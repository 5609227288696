import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";
import styled from 'styled-components/macro';
import PaymentForm from './views/NewContract';
import Overview from './views/Overview';
import ContractPreview from './views/ContractPreview';
import LoginForm from './views/Login';
import Contracts from './views/Contracts';
import { withFirebase, withAuth, AuthContext, FirebaseContext } from './components/Firebase';
import Header from './components/UI/Header/Header';
import StoreProvider from './store/StoreProvider';
import { reducer } from './store/reducers/templatesReducer';
const Wrapper = styled.div`
    /* color: #666666; */
    background: #F7F6F2;
    /* display: flex; */
    min-height: 100%;
`;

const Layout = styled.div`
    /* display: flex; */
    height: 100%;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 50px;

    @media print {
      padding: 0px;
      margin: 0px;
    }
`;


interface Props {
}

const App = (props: Props) => {
  const firebase = useContext(FirebaseContext);
  const [authUser, setAuthUser] = useState<any | null>(null);

  // TODO: Move this effect alogn with Provider to withAuth HoC
  useEffect(() => {
    const listener = firebase.auth.onAuthStateChanged(authUser => {
      authUser ? setAuthUser(authUser) : setAuthUser(null);
    });
    // TODO
    // return listener();
  }, []);

  return (
    <Wrapper>
      <StoreProvider rootReducer={reducer}>
        <AuthContext.Provider value={authUser}>
          <Router>
            <Header />
            <Layout>
              <Switch>
                <Route path="/login" exact component={LoginForm} />
                {authUser ? <>
                  <Route path="/" exact component={PaymentForm} />
                  <Route path="/overview/:id" exact component={Overview} />
                  <Route path="/contracts" exact component={Contracts} />
                  <Route path="/contracts/:id" exact component={ContractPreview} />
                </>
                  :
                  <Redirect exact to="/login" />
                }
              </Switch>
            </Layout>
          </Router>
        </AuthContext.Provider>
      </StoreProvider>
    </Wrapper>
  );
}

export default App;
