import React from 'react';
import styled from "styled-components/macro";
import { FormikProps, FieldProps } from "formik";

interface InputProps {
    error?: any
}

const StyledInput = styled.input`
    padding: 0.5rem;
    width: 100%;
    border: 1px solid ${(props: InputProps) => props.error ? 'red' : '#cdcdcd'};
    border-radius: 4px;
    font-size: 0.8rem;

    &:focus {
        outline: none;
        border: 1px solid #1E6BD6;
    }

    &:read-only {
        background: #EFEFEF;
    }
`;

const FormikInput = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...rest
}: FieldProps & { readonly?: boolean; onMouseDown?: (e: React.MouseEvent) => void }) => (
        <StyledInput type="text" autoComplete="off" error={touched[field.name] && errors[field.name]} {...field} {...rest} />
    );

export default FormikInput;
export { FormikInput, StyledInput };
