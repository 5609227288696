import React, { useState, useContext } from 'react';
import styled, { keyframes } from "styled-components/macro";
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { validate } from "../../utils/formValidation";
import { Formik, Form, Field, FieldProps, ErrorMessage } from "formik";
import Button from "../../components/UI/Button";
import TextInput from "../../components/UI/TextInput";
import Textarea from "../../components/UI/Textarea";
import Label from "../../components/UI/Label";
import ContractTemplatesManager from '../../components/ContractTemplatesManager';
import ErrorMessageWrapper from '../../components/UI/ErrorMessage';
import Calendar from 'react-calendar';
import { MyFormValues } from '../../store/actions/templatesActions';

import { getRoundedMonthlyPayment } from '../../utils/payments';
import { AuthContext, FirebaseContext } from '../../components/Firebase';
import { colors, contractVariables } from '../../config/constants';
import { mapContractToTags } from '../../utils/formatter';
export const FADE_IN = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const Wrapper = styled.div`
`;

const StyledInput = styled(TextInput)``;
const StyledTextarea = styled(Textarea)`
    height: 250px;
`;

const InputRow = styled.div`
    margin-bottom: 0.5rem;
`;

const Heading = styled.h3`
    font-weight: 600;
    color: ${colors.title};
`;

interface ColProps {
    grow?: number;
    padding?: string;
}

const Col = styled.div<ColProps>`
    flex-grow: ${props => props.grow || 1};
    flex-shrink: 1;
    flex-basis: auto;
    border: 1px solid ${colors.border};
    background: white;
    padding: ${props => props.padding || '16px'};
    border-radius: 6px;
`;

interface GridProps {
    gutter?: number;
}

const Grid = styled.div<GridProps>`
    display: flex;

    & ${Col}:first-of-type {
        margin-right: ${props => props.gutter ? `${props.gutter / 2}px` : '0'};
    }

    & ${Col}:nth-of-type():not(:last-of-type) {
        margin: 0 ${(props: { gutter?: number }) => props.gutter ? `${props.gutter}px` : '0'};
    }

    & ${Col}:last-of-type {
        margin-left: ${props => props.gutter ? `${props.gutter / 2}px` : '0'};
    }
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: #ccc;
    margin: 20px 0;
`;

const Backdrop = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.2); */
`;

const CalendarContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
`;

const StyledContractTemplatesManager = styled(ContractTemplatesManager)`
`;


const ContractGrid = styled(Grid)`
    margin-top: 1rem;
`;

const Tag = styled(Button)`
    margin-right: 4px;
    margin-bottom: 2px;
`;
const TagWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Actions = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-start;
`;

const formatDate = (date: Date): string => {
    if (date instanceof Date) {
        return date.toLocaleDateString()
    }
    return "";
}


const displayMonthlyPayment = (
    totalPayment: string,
    downPayment: string,
    interestPercentage: string,
    paymentDeadline: string
) => {
    const monthly = getRoundedMonthlyPayment(
        Number(totalPayment),
        Number(downPayment),
        Number(interestPercentage),
        Number(paymentDeadline)
    );
    if (isNaN(monthly) || !isFinite(monthly)) {
        return '0';
    } else {
        return monthly.toFixed(2);
    }
}

interface Props extends RouteComponentProps {
}

const NewContract = (props: Props) => {
    const firebase = useContext(FirebaseContext);

    const [isCalendarShown, showCalendar] = useState(false);

    // use this hook to recalc monthlyPayment when certain fields changes instead on every render
    // useEffect(() => {
    //     if (!isSanitized(values.field)) {
    //         setFieldValue('radius', sanitize(values.field));
    //     } else {
    //         action(values.field);
    //     }
    // }, [values.field]);

    const insertTagToEditor = (tag: string, values: any, setFieldValue: any) => {
        const formattedTag = `{{${tag}}}`;
        setFieldValue('contractTemplate', values['contractTemplate'] + formattedTag);
    }

    return (
        <Wrapper>
            {isCalendarShown && <Backdrop onClick={() => { showCalendar(false) }} />}
            <Formik
                initialValues={{
                    totalPayment: "",
                    downPayment: "",
                    interestPercentage: "",
                    paymentDeadline: "",
                    monthlyPayment: "",
                    firstPaymentDate: "",
                    fullname: "",
                    street: "",
                    city: "",
                    idNumber: "",
                    personalIdNumber: "",
                    contact: "",
                    contractTemplate: "",
                    itemName: ""
                }}
                validate={validate}
                onSubmit={(values, { setSubmitting }) => {
                    console.log(values);
                    // TODO: quick fix, values.monthlyPayment doesn't update
                    // calculate the value and use it in newly created contract
                    const monthlyPayment = displayMonthlyPayment(
                        values.totalPayment,
                        values.downPayment,
                        values.interestPercentage,
                        values.paymentDeadline);
                    firebase.addContract({ ...values, monthlyPayment }).then(doc => {
                        setSubmitting(false);
                        props.history.push(`/overview/${doc.id}`);
                    });
                    // localStorageDispatch(localStorageActions.addContract({...values}));
                    // setSubmitting(false);
                    // props.history.push(`/overview/${doc.id}`);

                }}
            >
                {({ isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <Grid gutter={20}>
                            <Col>
                                <Heading>Splátky</Heading>
                                <InputRow>
                                    <Label>
                                        Suma
                            <Field
                                            type="number"
                                            min="0"
                                            plain={false}
                                            name="totalPayment"
                                            placeholder="Suma"
                                            size="small"
                                            component={StyledInput}
                                        />
                                    </Label>
                                    <ErrorMessage name="totalPayment" component={ErrorMessageWrapper} />
                                </InputRow>

                                <InputRow>
                                    <Label>
                                        Akontácia
                            <Field
                                            type="number"
                                            min="0"
                                            step="0.1"
                                            name="downPayment"
                                            placeholder="Akontácia"
                                            size="small"
                                            component={StyledInput}
                                        />
                                    </Label>
                                    <ErrorMessage name="downPayment" component={ErrorMessageWrapper} />
                                </InputRow>

                                <InputRow>
                                    <Label>
                                        Úrok (%)
                            <Field
                                            type="number"
                                            min="0"
                                            step="0.5"
                                            name="interestPercentage"
                                            placeholder="Úrok"
                                            size="small"
                                            component={StyledInput}
                                        />
                                    </Label>
                                    <ErrorMessage name="interestPercentage" component={ErrorMessageWrapper} />
                                </InputRow>

                                <InputRow>
                                    <Label>
                                        Doba splatnosti (mesiace)
                            <Field
                                            type="number"
                                            min="1"
                                            name="paymentDeadline"
                                            placeholder="Doba splatnosti"
                                            size="small"
                                            component={StyledInput}
                                        />
                                    </Label>
                                    <ErrorMessage name="paymentDeadline" component={ErrorMessageWrapper} />
                                </InputRow>

                                <InputRow>
                                    <Label>
                                        Dátum prvej splátky
                            <Field
                                            type="date"
                                            name="firstPaymentDate"
                                            placeholder="Dátum prvej splátky"
                                            size="small"

                                        >
                                            {({ field, form }: FieldProps<MyFormValues>) => (
                                                <>
                                                    <StyledInput
                                                        // @ts-ignore
                                                        onMouseDown={() => { showCalendar(true) }}
                                                        field={{
                                                            ...field,
                                                            value: formatDate(field.value),
                                                            onChange: (e: any) => { e.preventDefault(); },
                                                        }} form={form} />
                                                    {isCalendarShown &&
                                                        <>
                                                            <CalendarContainer>
                                                                <Calendar
                                                                    onChange={e => {
                                                                        form.setFieldValue('firstPaymentDate', e);
                                                                        // TODO: input's onClick event is fired after date selection
                                                                        // following temp fix closes the calendar after this onClick event was fired
                                                                        // another workaround would be to use onMouseDown instead of onClick
                                                                        // setTimeout(() =>{showCalendar(false)}, 50);
                                                                        showCalendar(false);
                                                                    }}
                                                                    value={field.value || new Date()}
                                                                    locale="sk-SK"
                                                                />
                                                            </CalendarContainer>
                                                        </>
                                                    }
                                                </>
                                            )}
                                        </Field>
                                    </Label>
                                    <ErrorMessage name="firstPaymentDate" component={ErrorMessageWrapper} />
                                </InputRow>

                                <InputRow>
                                    <Label>
                                        Vypočítaná mesačná splátka
                            <Field
                                            type="text"
                                            name="monthlyPayment"
                                            placeholder="Mesačná splátka"
                                            size="small"
                                            readOnly
                                            value={displayMonthlyPayment(
                                                values.totalPayment,
                                                values.downPayment,
                                                values.interestPercentage,
                                                values.paymentDeadline)}
                                            component={StyledInput}
                                        />
                                    </Label>
                                    <ErrorMessage name="monthlyPayment" component={ErrorMessageWrapper} />
                                </InputRow>
                            </Col>

                            <Col>
                                <Heading>Nájomca</Heading>
                                <InputRow>
                                    <Label>
                                        Meno
                            <Field
                                            type="text"
                                            plain={false}
                                            name="fullname"
                                            placeholder="Meno"
                                            size="small"
                                            component={StyledInput}
                                        />
                                    </Label>
                                    <ErrorMessage name="fullname" component={ErrorMessageWrapper} />
                                </InputRow>

                                <InputRow>
                                    <Label>
                                        Ulica
                            <Field
                                            type="text"
                                            name="street"
                                            placeholder="Ulica"
                                            size="small"
                                            component={StyledInput}
                                        />
                                    </Label>
                                    <ErrorMessage name="street" component={ErrorMessageWrapper} />
                                </InputRow>

                                <InputRow>
                                    <Label>
                                        Mesto
                            <Field
                                            type="text"
                                            name="city"
                                            placeholder="Mesto"
                                            size="small"
                                            component={StyledInput}
                                        />
                                    </Label>
                                    <ErrorMessage name="city" component={ErrorMessageWrapper} />
                                </InputRow>

                                <InputRow>
                                    <Label>
                                        Číslo OP
                            <Field
                                            type="text"
                                            name="idNumber"
                                            placeholder="Číslo OP"
                                            size="small"
                                            component={StyledInput}
                                        />
                                    </Label>
                                    <ErrorMessage name="idNumber" component={ErrorMessageWrapper} />
                                </InputRow>

                                <InputRow>
                                    <Label>
                                        Rodné číslo
                            <Field
                                            type="text"
                                            name="personalIdNumber"
                                            placeholder="Rodné číslo"
                                            size="small"
                                            component={StyledInput}
                                        />
                                    </Label>
                                    <ErrorMessage name="personalIdNumber" component={ErrorMessageWrapper} />
                                </InputRow>

                                <InputRow>
                                    <Label>
                                        Kontakt
                            <Field
                                            type="text"
                                            name="contact"
                                            placeholder="Tel. číslo"
                                            size="small"
                                            component={StyledInput}
                                        />
                                    </Label>
                                    <ErrorMessage name="contact" component={ErrorMessageWrapper} />
                                </InputRow>
                            </Col>
                        </Grid>

                        <InputRow>
                            <ContractGrid gutter={20}>
                                <Col grow={1}>
                                    <Heading>Šablóna zmluvy</Heading>
                                    <InputRow>
                                        <Label>
                                            Produkt
                            <Field
                                                type="text"
                                                name="itemName"
                                                placeholder="Názov produktu"
                                                size="small"
                                                component={StyledInput}
                                            />
                                        </Label>
                                        <ErrorMessage name="itemName" component={ErrorMessageWrapper} />
                                    </InputRow>
                                    <InputRow>
                                        <Label>Premenné</Label>
                                        <TagWrapper>
                                            {contractVariables.map(v => (
                                                <Tag
                                                    type="button"
                                                    variant="primary"
                                                    inverse
                                                    key={v}
                                                    size="small"
                                                    onClick={() => insertTagToEditor(v, values, setFieldValue)}>
                                                    {`${v}: '${mapContractToTags(values)[v]}'`}
                                                </Tag>
                                            ))}
                                        </TagWrapper>
                                    </InputRow>

                                    <Label>
                                        Šablóna zmluvy
                        <Field
                                            type="text"
                                            name="contractTemplate"
                                            placeholder="Šablóna zmluvy"
                                            size="small"
                                            component={StyledTextarea}
                                        />
                                    </Label>
                                    <ErrorMessage name="contractTemplate" component={ErrorMessageWrapper} />
                                    <Button
                                        type="button"
                                        onClick={() => firebase.addTemplate(values.contractTemplate, values.itemName)}>
                                        Uložiť
                                    </Button>
                                </Col>
                                <Col>
                                    {/* <StyledBox> */}
                                    <Heading>Uložené šablóny</Heading>
                                    {/* <Label>
                                        Uložené šablóny
                                        </Label> */}
                                    <StyledContractTemplatesManager
                                        onSelect={(content: string) => setFieldValue('contractTemplate', content)}
                                        onRemove={() => { }}
                                    />
                                    {/* </StyledBox> */}
                                </Col>
                            </ContractGrid>
                        </InputRow>
                        <Divider />
                        <Actions>
                            <Button type="submit" variant="success" disabled={isSubmitting}>
                                Vytvoriť zmluvu
                        </Button>
                        </Actions>
                    </Form>
                )}
            </Formik>
        </Wrapper >
    )
};

export default withRouter(NewContract);
