import React, { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import styled, { } from "styled-components/macro";
import Button from "../../components/UI/Button";


import { templateFormatter, mapContractToTags } from '../../utils/formatter';
import { FirebaseContext } from '../../components/Firebase';

const Wrapper = styled.div`
    display: flex;
    width: 210mm;
    height: 297mm;
    margin: 0 auto;
    background: white;
    flex-direction: column;

    @media screen {
        padding: 2.0cm;
    }
`;

const Row = styled.div`
    display: flex;
    width: 100%;
`;


const Col = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const Heading = styled.h1`
    text-align: center;
    width: 100%;
`;

const Content = styled.div`
    padding-top: 3cm;
    /* breaks line at newline char */
    white-space: pre-line; 
`;

const Spacer = styled.div`
    padding: 2cm 0px;
`;

const PartyName = styled.div`
    font-weight: bold;
    padding-bottom : 0.3cm;

`;

const PartyDetails = styled.div`
`;

const Party = styled(Col)`
    flex: 0 1 auto;
`

const Parties = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-around;
    /* border: 1px solid #444; */
`;

// const Divider = styled.div`
//     width: 1px;
//     height: 100%;
//     background: #444;
// `;

const Footer = styled.div`
    display: flex;
    flex: 1;
    align-items: flex-end;
    text-align: center;
`;

const Toolbar = styled.div`
    display: flex;
    margin-bottom: 10px;
    width: 210mm;
    margin: 0 auto;

    ${Button} + ${Button} {
        margin-left: 10px;
    }

    @media print {
        display: none;
    }
`;

const Left = styled.div`
    flex: 1;
`;

const Right = styled.div`
    display: flex;
    justify-items: flex-end;
`;

interface Params {
    id: string
}

const ContractPreview = ({ match, history }: RouteComponentProps<Params>) => {
    // const [{ contracts }, localStorageDispatch] = useReducer(localStorageReducer.reducer, localStorageReducer.initialState);
    const firebase = useContext(FirebaseContext);
    const [loading, setLoading] = useState(false);
    const [contract, setContract] = useState(null);
    useEffect(() => {
        setLoading(true);
        const contractID = match.params.id;
        firebase.contract(contractID).then(snapshot => {
            setContract(snapshot.data())
            setLoading(false);
        })
        // const contracts = localStorageUtils.loadContracts();
        // contracts.forEach(c => {
        //     localStorageDispatch(localStorageActions.addContractFromStorage(c))
        // });

    }, []);

    if (loading) {
        return (
            <Wrapper>Načítavam</Wrapper>
        );
    }
    return (
        <>
            <Toolbar>
                <Left>
                    <Button inverse onClick={() => history.goBack()}>Spať</Button>
                </Left>
                {contract && (
                    <Right>
                        <Button inverse onClick={() => window.print()}>Vytlačiť zmluvu</Button>
                    </Right>
                )}
            </Toolbar>
            <Wrapper>
                <Row>
                    <Heading>Kúpno-predajná zmluva</Heading>
                </Row>
                <Row>
                    <Spacer>
                        medzi:
                </Spacer>
                </Row>
                <Row>
                    <Parties>
                        <Party>
                            <PartyName>Predajca:</PartyName>
                            <PartyDetails>
                                Eurotrend Špak s.r.o.<br />
                                Zimná 17<br />
                                05901 Spišská Belá<br />
                                IČO: 47602414<br />
                                IČ DPH: SK2023987449<br />
                                tel. 052/4596355<br />
                            </PartyDetails>
                        </Party>
                        <Party>
                            <PartyName>Kupujúci:</PartyName>
                            <PartyDetails>
                                {contract && contract.tenant.fullname}<br />
                                {contract && contract.tenant.street}<br />
                                {contract && contract.tenant.city}<br />
                                {contract && contract.tenant.contact && (
                                    <>
                                        {contract.tenant.contact}
                                        <br />
                                    </>
                                )}
                                {contract && contract.tenant.idNumber && (
                                    <>
                                        Číslo OP:{' '}
                                        {contract.tenant.idNumber}
                                        <br />
                                    </>
                                )}
                                {contract && contract.tenant.personalIdNumber && (
                                    <>
                                        RČ:{' '}
                                        {contract.tenant.personalIdNumber}
                                        <br />
                                    </>
                                )}
                            </PartyDetails>
                        </Party>
                    </Parties>
                </Row>
                <Row>
                    <Content>
                        {contract && templateFormatter(contract.contractTemplate, mapContractToTags(contract))}
                    </Content>
                </Row>
                <Footer>
                    <Col>
                        ..............................<br />Predávajúci
                </Col>
                    <Col>
                        ..............................<br />Kupujúci
                </Col>
                </Footer>
            </Wrapper>
        </>
    )
};

export default ContractPreview;
