import React, { useState, useReducer, useEffect, useContext } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from "styled-components/macro";
import { validate } from "../../utils/formValidation";
import Button from "../../components/UI/Button";
import { StyledInput as TextInput } from "../../components/UI/TextInput";
import Label from "../../components/UI/Label";
import ErrorMessage from '../../components/UI/ErrorMessage';
import { withFirebase, FirebaseContext, AuthContext } from '../../components/Firebase';
import Modal from '../../components/UI/Modal';
import { migrateDataFromLocalStorage } from '../../utils/localStorage';

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: #ccc;
    margin: 20px 0;
`;

const InputRow = styled.div`
    margin-bottom: 1rem;
`;

const Heading = styled.h2`
    text-align: center;
    font-weight: 500;
`;

const SubmitButton = styled(Button)`
    width: 100%;
`;

interface Props extends RouteComponentProps {
    firebase: any;
}

const LoginForm = ({ history }: Props) => {
    const firebase = useContext(FirebaseContext);
    const authUser = useContext(AuthContext);
    const [form, setValue] = useState({
        username: '',
        password: '',
        error: null,
        loading: false,
    });

    useEffect(() => {
        if (authUser) {
            migrateDataFromLocalStorage(firebase)
            history.push('/');
        }
    }, [authUser])

    const updateField = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...form,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        setValue({ ...form, loading: true });
        firebase.signIn(form.username, form.password).then(authUser => {
            console.log(authUser);
            setValue({ ...form, loading: false });
            history.push('/');
        }).catch((error: any) => {
            setValue({ ...form, loading: false, error: error.message ? error.message : error })
        })
        e.preventDefault();
    }

    return (
        <Modal>
            <Heading>Prihlásenie</Heading>
            <form onSubmit={handleSubmit} method="POST">
                <InputRow>
                    <Label>
                        E-mail:
                            <TextInput
                            type="text"
                            name="username"
                            placeholder="E-mail"
                            onChange={updateField}
                        />
                    </Label>
                </InputRow>
                <InputRow>
                    <Label>
                        Heslo:
                            <TextInput
                            type="password"
                            name="password"
                            placeholder="Heslo"
                            onChange={updateField}
                        />
                    </Label>
                </InputRow>
                {form.error ? <ErrorMessage>{form.error}</ErrorMessage> : null}

                <Divider />
                <SubmitButton type="submit" variant="success">
                    {!form.loading && 'Prihlásiť'}
                    {form.loading && 'Prihlásiť...'}
                </SubmitButton>
            </form>
        </Modal>
    )
};

export default withRouter(LoginForm);
