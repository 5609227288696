import React, { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import styled from "styled-components/macro";
import Button from "../../components/UI/Button";
import { Link } from 'react-router-dom'
import { getMonthlyPayments } from '../../utils/payments';

import { timestampFormatter } from '../../utils/formatter';
import { colors } from '../../config/constants';
import { FirebaseContext, AuthContext } from '../../components/Firebase';

const Wrapper = styled.div`
    height: 100%;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid ${colors.border};
    font-size: 0.9rem;
`;

const TD = styled.td`
    border-bottom: 1px solid${colors.border};
    padding-right: 10px;
    text-align: right;
	padding: 8px 4px;
    color: #333;
    background: #F4F4F4;
`

const TDSummary = styled(TD)`
    font-weight: bold;
`;

const TH = styled.th`
    background: white;
	border: 1px solid  ${colors.border};
    padding: 10px 6px;
    font-weight: normal;
`;

const TR = styled.tr`
    &:nth-child(even) td { 
        background: #fff; 
    }
`;

const Toolbar = styled.div`
    display: flex;
    margin-bottom: 10px;

    ${Button} + ${Button} {
        margin-left: 10px;
    }

    @media print {
        display: none;
    }
`;

const Left = styled.div`
    flex: 1;
`;

const StyledButton = styled(Button)`
    margin-right: 4px;
`;

const Right = styled.div`
    display: flex;
    justify-items: flex-end;
`;


interface Params {
    id: string
}

const Overview = ({ match, history }: RouteComponentProps<Params>) => {
    const firebase = useContext(FirebaseContext);
    const authUser = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [contract, setContract] = useState(null);

    useEffect(() => {
        if (authUser) {
            setLoading(true);
            const contractID = match.params.id;
            firebase.contract(contractID).then(snapshot => {
                setContract({ id: contractID, ...snapshot.data() })
                setLoading(false);
                setError(false);
            }).catch(error => {
                setLoading(false);
                setError(error);
                console.error(error);
            })
        }
    }, [authUser]);

    let payments: any[] = [];
    let amountToPay: number | null = null;
    let amountInInterest: number | null = null;

    if (contract) {
        amountToPay = (contract.totalPayment - contract.downPayment);
        amountInInterest = (contract.totalPayment - contract.downPayment) * (contract.interestPercentage / 100);
        payments = getMonthlyPayments(
            contract.totalPayment,
            contract.downPayment,
            contract.interestPercentage,
            contract.paymentDeadline,
            //TODO: convert to date inside in firebase
            // new Date(contract.firstPaymentDate)
            contract.firstPaymentDate.toDate()
        );
    }

    let message = null;
    if (error) (
        message = "Nastala chyba pri načítavaní"
    )
    else if (!loading && !contract) (
        message = "Zmluva nenájdená"
    )
    if (loading) (
        message = "Načítavam..."
    )

    return (
        <Wrapper>
            <Toolbar>
                <Left>
                    <StyledButton inverse variant="primary" onClick={() => history.goBack()}>Spať</StyledButton>
                </Left>
                {contract && (
                    <Right>
                        <Link to={`/contracts/${contract.id}`}> <StyledButton inverse variant="success">Zobraziť zmluvu</StyledButton></Link>
                        <StyledButton inverse onClick={() => window.print()}>Vytlačiť kalendár</StyledButton>
                    </Right>
                )}
            </Toolbar>
            <Table>
                <thead>
                    <tr>
                        <TH>Číslo splátky</TH>
                        <TH>Dátum splatnosti</TH>
                        <TH>Suma</TH>
                        <TH>Dátum platby</TH>
                        <TH>Podpis prenajímateľa</TH>
                        <TH>Podpis nájomcu</TH>
                    </tr>
                </thead>
                <tbody>
                    {message && (
                        <TR >
                            <TD colSpan={6} style={{ textAlign: 'center' }}>{message}</TD>
                        </TR>
                    )}
                    {payments.map((p, i) => (
                        <TR key={i}>
                            <TD>{i + 1}</TD>
                            <TD>{timestampFormatter(p.timestamp, "date")}</TD>
                            <TD>{p.value.toFixed(2)} Eur</TD>
                            <TD></TD>
                            <TD></TD>
                            <TD></TD>
                        </TR>
                    ))}
                    {contract && (
                        <TR>
                            <TDSummary colSpan={3} style={{ textAlign: 'right' }}>
                                Suma spolu:{' '}
                                {amountToPay && amountToPay.toFixed(2)}
                                {' '} Eur
                                <br />
                                Navýšenie:{' '}
                                {amountInInterest && amountInInterest.toFixed(2)}
                                {' '} Eur
                            </TDSummary>
                            <TD></TD>
                            <TD></TD>
                            <TD></TD>
                        </TR>
                    )}
                </tbody>
            </Table>
        </Wrapper >
    )
};

export default Overview;
