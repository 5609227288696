import React, { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import styled, { css } from "styled-components/macro";
import Button from "../../components/UI/Button";
import { Link } from 'react-router-dom'
import { timestampFormatter } from '../../utils/formatter';
import { colors } from '../../config/constants';
import { FirebaseContext, AuthContext } from '../../components/Firebase';

const Wrapper = styled.div`
    height: 100%;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid ${colors.border};
    font-size: 0.9rem;
`;

const TD = styled.td<{ type?: 'date' }>`
    border-bottom: 1px solid${colors.border};
    border-right: 1px solid${colors.border};
    padding-right: 10px;
    text-align: right;
	padding: 8px 4px;
    color: #333;
    background: #F4F4F4;

    ${props => props.type === 'date' && css`
        width: 15%;
    `}
`


const TH = styled.th`
    background: white;
	border: 1px solid  ${colors.border};
    padding: 10px 6px;
    font-weight: normal;
`;

const TR = styled.tr`
    &:nth-child(even) td { 
        background: #fff; 
    }
`;

const Toolbar = styled.div`
    display: flex;
    margin-bottom: 10px;

    ${Button} + ${Button} {
        margin-left: 10px;
    }

    @media print {
        display: none;
    }
`;

const Left = styled.div`
    flex: 1;
`;

const StyledButton = styled(Button)`
    margin-right: 4px;
`;



interface Params {
    id: string
}

const Contracts = ({ history }: RouteComponentProps<Params>) => {
    const firebase = useContext(FirebaseContext);
    const authUser = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [contracts, setContracts] = useState([]);
    useEffect(() => {
        if (authUser) {
            setLoading(true);
            const allContracts = [];
            firebase.contracts(authUser.uid).then(snapshot => {
                snapshot.forEach(doc => {
                    allContracts.push({ ...doc.data(), id: doc.id });
                });
                // TODO: sort with firebase (newest on top)
                setContracts(allContracts.sort((a, b) => b.timestamp - a.timestamp));
                setLoading(false);
                setError(null);
            }).catch(error => {
                setError(error);
                console.error(error);
            })
        }
    }, [authUser]);

    return (
        <Wrapper>
            <Toolbar>
                <Left>
                    <StyledButton inverse variant="primary" onClick={() => history.goBack()}>Spať</StyledButton>
                </Left>
            </Toolbar>
            <Table>
                <thead>
                    <tr>
                        {/* <TH>ID</TH> */}
                        <TH>Dátum vytvorenia</TH>
                        <TH>Zákazník</TH>
                        <TH>Produkt</TH>
                        <TH>Dĺžka splácania</TH>
                        <TH>Suma</TH>
                        <TH></TH>
                    </tr>
                </thead>
                <tbody>
                    {error && (
                        <TR >
                            <TD colSpan={6} style={{ textAlign: 'center' }}>Nastala chyba pri načítavaní</TD>
                        </TR>
                    )}
                    {loading && !error && (
                        <TR >
                            <TD colSpan={6} style={{ textAlign: 'center' }}>Načítavam ({contracts.length})...</TD>
                        </TR>
                    )}
                    {!loading && !error && contracts.length === 0 && (
                        <TR >
                            <TD colSpan={6} style={{ textAlign: 'center' }}>Žiadne vytvorené splátkové zmluvy</TD>
                        </TR>
                    )}
                    {contracts.map((contract) => (
                        <TR key={contract.id}>
                            {/* <TD>{contract.id}</TD> */}
                            <TD type="date">{timestampFormatter(contract.timestamp, "date")}</TD>
                            <TD>{contract.tenant.fullname}</TD>
                            <TD>{contract.itemName}</TD>
                            <TD>{contract.paymentDeadline}</TD>
                            <TD>{contract.totalPayment} Eur</TD>
                            <TD style={{ textAlign: 'right' }}>
                                <Link to={`/overview/${contract.id}`}><Button size="small" inverse>Otvoriť</Button></Link>
                            </TD>
                        </TR>
                    ))}
                </tbody>
            </Table>
        </Wrapper >
    )
};

export default Contracts;
