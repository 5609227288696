import styled, { css } from 'styled-components/macro';
import { colors } from '../../../config/constants';

// TODO: disabled btn

interface ButtonProps {
    variant?: string
    size?: string
    inverse?: boolean
    rounded?: boolean
}

const getColor = (variant?: string): string => {
    return variant ? colors[variant] : colors.primary;
}

export const Button = styled.button<ButtonProps>`
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    cursor: pointer;
    padding: 6px 10px;
    border: 1px solid ${(props) => getColor(props.variant)};
    border-radius: ${(props) => props.rounded ? '50px' : '3px'};
    background: ${(props) => getColor(props.variant)};
    color: ${props => props.variant === "secondary" ? '#2f2f2f' : 'white'};
    text-decoration: none;
    font-family: sans-serif;
    font-size: .95rem;
    transition: all 0.2s ease;

    ${props => props.size === 'small' && css`
        padding: 0.3rem 0.5rem;
        font-size: .75rem;
    `}

    ${props => props.size === 'large' && css`
        padding: .8rem 1rem;
    `}

    &:hover {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.15);
    }

    ${props => props.inverse && css`
        border-color: ${getColor(props.variant)};
        background: white;
        color: ${props.variant === "secondary" ? '#2f2f2f' : getColor(props.variant)};

        &:hover {
            background:  ${getColor(props.variant)};
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.15);
            color: ${props.variant === "secondary" ? '#2f2f2f' : 'white'};
        }
    `}

`;

export default Button;
