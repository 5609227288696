import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import { credentials } from '../../config/firebase';
import { MyFormValues } from '../../store/actions/templatesActions';
import { Contract, Template } from '../../store/reducers/templatesReducer';

class Firebase {
    app: firebase.app.App;
    auth: firebase.auth.Auth;
    db: firebase.database.Database;
    firestore: firebase.firestore.Firestore;

    constructor() {
        this.app = firebase.initializeApp(credentials);
        this.auth = firebase.auth();
        // this.db = firebase.database();
        this.firestore = firebase.firestore();
    }

    signUp = async (email: string, password: string) => {
        const authUser = await this.auth.createUserWithEmailAndPassword(email, password);
        if (authUser) {
            // create entry in user database
            this.firestore.collection('users').doc(authUser.user.uid).set({ email });
        }
        return authUser;
    }

    signIn = (email: string, password: string) => {
        return this.auth.signInWithEmailAndPassword(email, password);
    }

    signOut = () => this.auth.signOut();

    // doPasswordUpdate = (password: string) => {
    //     return this.auth.currentUser.updatePassword(password);
    // }

    contracts = (userId: string) => {
        return this.firestore.collection('contracts').where("userId", "==", userId).get()
    };

    contract = (uuid: string) => this.firestore.collection(`contracts`).doc(uuid).get();

    addContract = async ({
        totalPayment,
        downPayment,
        interestPercentage,
        paymentDeadline,
        monthlyPayment,
        firstPaymentDate,
        fullname,
        street,
        city,
        idNumber,
        personalIdNumber,
        contact,
        contractTemplate,
        itemName }: MyFormValues) => {
        // const newConctractRef = this.firestore.collection('contracts').doc();
        const contract: Omit<Contract, 'id'> = {
            totalPayment: Number(totalPayment),
            downPayment: Number(downPayment),
            interestPercentage: Number(interestPercentage),
            paymentDeadline: Number(paymentDeadline),
            monthlyPayment: Number(monthlyPayment),
            firstPaymentDate,
            tenant: {
                fullname,
                street,
                city,
                idNumber,
                personalIdNumber,
                contact,
            },
            contractTemplate,
            itemName,
            userId: this.auth.currentUser.uid,
            timestamp: new Date().getTime()
        }
        return await this.firestore.collection(`contracts`).add(contract)
    }

    templates = (userId: string) => this.firestore.collection('templates').where("userId", "==", userId);

    template = (uuid: string) => this.firestore.collection(`templates`).doc(uuid).get();

    addTemplate = async (content: string, itemName: string) => {
        const template: Omit<Template, 'id'> = {
            content,
            itemName,
            userId: this.auth.currentUser.uid,
            timestamp: new Date().getTime()
        }
        return await this.firestore.collection(`templates`).add(template);
    }

    removeTemplate = async (uuid: string) => {
        return await this.firestore.collection(`templates`).doc(uuid).delete();
    }

    users = () => this.firestore.collection('users');
    user = (uuid: string) => this.firestore.collection(`users`).doc(uuid).get();
}


export default Firebase;