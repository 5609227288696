import React, { useState, useEffect, useContext } from "react";
import styled from 'styled-components/macro';
import Button from "../../components/UI/Button";
import { FirebaseContext, AuthContext } from "../../components/Firebase";
import { Template } from '../../store/reducers/templatesReducer';
import { timestampFormatter } from "../../utils/formatter";
import { colors } from "../../config/constants";

const Wrapper = styled.div`

`;
const TemplatesWrapper = styled.div`
    min-height: 250px;
    max-height: 450px;
    overflow: auto;
`;

const ActionsWrapper = styled.div`
`;

const EmptyText = styled.div`
    font-size: 0.8rem;
    color: #444;
    text-align: center;
`;

const Item = styled.div<StyledProps>`
    display: flex;
    font-weight: normal;
    padding: 6px 16px;
    background: ${(props) => props.isSelected ? colors.primary : 'white'};
    color: ${(props) => props.isSelected ? 'white' : 'inherit'};
    border: 1px solid #e2e2e2;
    border-radius: 3px;
    align-items: center;
    cursor: pointer;
    font-size: 0.8rem;

    &:not(:last-child) {
        margin-bottom: 0.2rem;
    }
`;

const Content = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    flex: 1 1 auto;
    /* TODO text truncation not working */
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
`;

const Timestamp = styled.div<StyledProps>`
    flex: 1 1 auto;
    font-style: italic;
    color: ${props => props.isSelected ? 'white' : '#444'};
    font-size: 0.7rem;
`;

const Action = styled.div`
    flex: 1 1 auto;
    display: flex;
    justify-items: flex-end;
    justify-content: flex-end;
`;

interface StyledProps {
    isSelected?: boolean,
}

interface OwnProps {
    onRemove: any,
    onSelect: any,
    className?: string
}

type Props = StyledProps & OwnProps;

const ContractTemplatesManager = ({ onRemove, onSelect, className }: Props) => {
    const [selected, setSelected] = useState(-1);
    const firebase = useContext(FirebaseContext);
    const authUser = useContext(AuthContext);

    const [templates, setTemplates] = useState<Template[]>([]);
    const [loading, setLoading] = useState(false);
    const [, setError] = useState(null);

    // run once at initial render
    useEffect(() => {
        if (authUser) {
            setLoading(true);
            const unsubscribe = firebase.templates(authUser.uid).onSnapshot(snapshot => {
                const allTemplates = [];
                snapshot.forEach(doc => {
                    allTemplates.push({ ...doc.data(), id: doc.id });
                });
                setTemplates(allTemplates);
                setLoading(false);
                setError(null);
            }, error => {
                setError(error);
                console.error(error);
            });
            // }).catch(error => {
            //     setError(error);
            //     console.error(error);
            // })
            return () => unsubscribe();
        }
    }, [authUser]);

    const removeHandler = (id: string) => {
        setSelected(-1);
        firebase.removeTemplate(id);
        onRemove(id);
    }

    return (
        <Wrapper className={className}>
            <TemplatesWrapper>
                {loading && (
                    <EmptyText>Načítavam...</EmptyText>
                )}
                {!loading && templates.length === 0 && (
                    <EmptyText>Žiadne uložené šablóny. Novú šablónu uložíte kliknutím na "Pridať do uložených"</EmptyText>
                )}
                {templates.map((template, i) => {
                    const isSelected = selected === i;
                    return (
                        <Item tabIndex={0} key={template.id} isSelected={isSelected} onClick={() => { setSelected(i); onSelect(template.content); }}>
                            <Content>
                                <Title>{template.content.substr(0, 50)}</Title>
                                <Timestamp isSelected={isSelected}>Vytvorené: {timestampFormatter(template.timestamp)}</Timestamp>
                            </Content>
                            <Action>
                                <Button
                                    inverse
                                    size="small"
                                    type="button"
                                    variant="danger"
                                    onClick={(e) => { e.stopPropagation(); removeHandler(template.id) }}>
                                    X
                            </Button>
                            </Action>
                        </Item>
                    )
                })}
            </TemplatesWrapper>
            <ActionsWrapper>
            </ActionsWrapper>
        </Wrapper>
    )
};

export default ContractTemplatesManager;
