export const timestampFormatter = (timestamp: number, format: string = "full"): string => {
    try {
        const date = new Date(timestamp);
        switch (format) {
            case "full":
                return date.toLocaleString();
                break;
            case "date":
                return date.toLocaleDateString();
                break;
            case "date":
                return date.toLocaleTimeString();
                break;
            default:
                throw "Unknown format option";

                break;
        }
    } catch (e) {
        console.log(`Could not format timestamp '${timestamp}'`);
        console.error(e);
        return timestamp.toString();
    }
}

export const templateFormatter = (str: string, data: { [key: string]: any }): string => {
    const regexpMatchingBrackets = new RegExp('({{.*?}})', 'g');
    const tokensWithBrackets = str.match(regexpMatchingBrackets);

    let source = str;
    if (tokensWithBrackets) {
        tokensWithBrackets.forEach((token) => {
            const tokenName = token.substring(2, token.length - 2); // first two and last two chars are brackets
            if (data[tokenName] !== undefined)
                source = source.replace(token, data[tokenName]);
        })
    }
    return source;
}

export const mapContractToTags = (contract: any) => {
    console.log(contract);
    const firstPaymentDateObj = new Date(contract.firstPaymentDate.seconds * 1000);
    return {
        'suma': contract.totalPayment,
        'dobaSplatnosti': contract.paymentDeadline,
        'mesacnaSplatka': contract.monthlyPayment,
        'den': firstPaymentDateObj.getDate(),
        'akontacia': contract.downPayment,
        'prvaSplatka': firstPaymentDateObj.toLocaleDateString(),
        'datum': new Date().toLocaleDateString(),
        'produktNazov': contract.itemName,
    }
}