import { Template, Contract } from '../store/reducers/templatesReducer';
import { MyFormValues } from '../store/actions/templatesActions';

export const save = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
}

export const load = (key: string): any[] => {
    const item = localStorage.getItem(key);
    if (item) {
        return JSON.parse(item);
    }
    return [];
}

export const loadTemplates = (): Template[] => {
    const templates: Template[] = load('templates');
    return templates
}

export const addTemplate = (template: Template) => {
    const templates: Template[] = load('templates');
    if (templates) {
        templates.push(template)
        save('templates', templates);
    }
}

export const removeTemplate = (id: string) => {
    const templates: Template[] = load('templates');
    if (templates) {
        save('templates', templates.filter(t => t.id !== id));
    }
}

export const addContract = (contract: Contract) => {
    const contracts: Contract[] = load('contracts');
    if (contracts) {
        contracts.push(contract)
        save('contracts', contracts);
    }
}

export const removeContract = (id: string) => {
    const contracts: Contract[] = load('contracts');
    if (contracts) {
        save('contracts', contracts.filter(c => c.id !== id));
    }
}

export const loadContracts = (): MyFormValues[] => {
    const contracts: MyFormValues[] = load('contracts');
    return contracts;
}

export const migrateDataFromLocalStorage = (firebase) => {
    const migrated = JSON.parse(localStorage.getItem('migrate'))
    if (!migrated) {
        const storedTemplates = loadTemplates();
        const storedContracts = loadContracts();
        console.log('templates', storedTemplates);
        console.log('storedContracts', storedContracts);
        storedTemplates.forEach(t => {
            firebase.addTemplate(t.content, '')
        });
        storedContracts.forEach(c => {
            firebase.addContract(c);
        });
        save('migrate', true);
    }
}