import * as actionTypes from '../actions/actionTypes';
import { localStorageAction } from '../actions/templatesActions'

export interface State {
    templates: Template[],
    contracts: Contract[],
}

export interface Template {
    id: string,
    itemName: string,
    content: string,
    userId: string,
    timestamp: number,
}

export interface Contract {
    id: string,
    totalPayment: number;
    downPayment: number;
    interestPercentage: number;
    firstPaymentDate: string;
    paymentDeadline: number;
    monthlyPayment: number;
    tenant: {
        fullname: string;
        street: string;
        city: string;
        idNumber: string;
        personalIdNumber: string;
        contact: string;
    }
    contractTemplate: string;
    itemName: string;
    timestamp: number,
    userId: string,
}

export const initialState: State = {
    templates: [],
    contracts: [],
};

const addTemplate = (state: State, template: Template) => {
    return {
        ...state,
        templates: [...state.templates, template]
    }
}

const removeTemplate = (state: State, id: string) => {
    return {
        ...state,
        templates: state.templates.filter(t => t.id !== id),
    }
}

const addContract = (state: State, contract: Contract) => {
    return {
        ...state,
        contracts: [...state.contracts, contract]
    }
}

const removeContract = (state: State, id: string) => {
    return {
        ...state,
        contracts: state.contracts.filter(c => c.id !== id),
    }
}

export const reducer = (state = initialState, action: localStorageAction) => {
    switch (action.type) {
        case actionTypes.TEMPLATE_ADD:
            return addTemplate(state, action.template);

        case actionTypes.TEMPLATE_REMOVE:
            return removeTemplate(state, action.id);

        case actionTypes.CONTRACT_ADD:
            return addContract(state, action.contract);

        case actionTypes.CONTRACT_REMOVE:
            return removeContract(state, action.id);

        default:
            return state;
    }
};