
interface Payment {
    timestamp: number,
    value: number,
}

export const getRoundedMonthlyPayment = (
    totalPayment: number,
    downPayment: number,
    interestPercentage: number,
    paymentDeadline: number,
): number => {
    const interestPayment = (totalPayment - downPayment) * (interestPercentage / 100);
    const toPay = totalPayment - downPayment + interestPayment;
    const monthlyPayment = toPay / paymentDeadline;
    // round up to tens of cents (aka to 1 decimal place)
    const monthlyPaymentRounded = Math.ceil(monthlyPayment * 10) / 10;
    return monthlyPaymentRounded;
}

export const getMonthlyPayments = (
    totalPayment: number,
    downPayment: number,
    interestPercentage: number,
    paymentDeadline: number,
    firstPaymentDate: Date
): Payment[] => {
    const interestPayment = (totalPayment - downPayment) * (interestPercentage / 100);
    const toPay = totalPayment - downPayment + interestPayment;
    const monthlyPayment = toPay / paymentDeadline;
    // round up to tens of cents (aka to 1 decimal place)
    const monthlyPaymentRounded = getRoundedMonthlyPayment(totalPayment, downPayment, interestPercentage, paymentDeadline);
    const lastMonthPayment = toPay - monthlyPaymentRounded * (paymentDeadline - 1);

    let payments: Payment[] = [];
    for (let i = 0; i < paymentDeadline - 1; i++) {
        const paymentDate = new Date(firstPaymentDate.getTime()).setMonth(firstPaymentDate.getMonth() + i);
        const p: Payment = {
            timestamp: paymentDate,
            value: monthlyPaymentRounded
        }
        payments.push(p);
    }

    // payment for the last month will be lower than previous payments due to rounding up
    payments.push({
        timestamp: new Date(firstPaymentDate.getTime()).setMonth(firstPaymentDate.getMonth() + paymentDeadline),
        value: lastMonthPayment
    });
    return payments;
}
