import React from 'react';
import styled from "styled-components/macro";
import { FormikProps, FieldProps } from "formik";

interface TextAreaProps {
    error?: any
}

const StyledTextarea = styled.textarea`
    padding: 0.5rem;
    width: 100%;
    border: 1px solid ${(props: TextAreaProps) => props.error ? 'red' : '#cdcdcd'};
    border-radius: 4px;
    font-size: 0.8rem;

    &:focus {
        outline: none;
        border: 1px solid #1E6BD6;
    }
`;

const Textarea = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...rest
}: FieldProps) => (
        <StyledTextarea autoComplete="off" error={touched[field.name] && errors[field.name]} {...field} {...rest} />
    );

export default Textarea;
