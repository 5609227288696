import React, { useContext } from 'react';
import styled from "styled-components/macro";
import Button from '../Button';
import { FirebaseContext, AuthContext } from '../../Firebase';
import { Link } from 'react-router-dom';
import Logo from '../../../images/logo.svg';

const Wrapper = styled.div`
    position: fixed;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    width: 100%;
    background: white;
    border-bottom: 1px solid #cecece;
    display: flex;
    top: 0px;

    @media print {
        display: none;
    }
`;

const WidthLimiter = styled.div`
    display: flex;
    margin: 0 auto;
    max-width: 1200px;
    padding: 8px 16px;
    flex: 1;
`;
const Toolbar = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`;

const Menu = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
`;

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const LogoImg = styled.img`
    height: 32px;
`;

const Title = styled.h2`
    margin-bottom: 0;
    margin-left: 0.5rem;
    color: #d9492f;
`;

const MenuItem = styled.span`
    margin-right: 0.5rem;
    color: #d9492f;
    padding: 5px;

    &:hover {
        text-decoration: underline;
    }
`;

const Divider = styled.div`
    width: 1px;
    height: 32px;
    flex: 1;
    background: #FF8B73;
    margin: 0 15px;
`;

const Username = styled.div`
    margin-right: 10px;
`;

const Header = () => {
    const firebase = useContext(FirebaseContext);
    const authUser = useContext(AuthContext);

    return (
        <Wrapper>
            <WidthLimiter>
                <Toolbar>
                    <LogoWrapper>
                        <LogoImg src={Logo} />
                        <Link to="/"><Title>Splátky</Title></Link>
                        {authUser &&
                            <>
                                <Divider />
                                <Link to="/"><MenuItem>Nová</MenuItem></Link>
                                <Link to="/contracts"><MenuItem>História</MenuItem></Link>
                            </>
                        }
                    </LogoWrapper>
                </Toolbar>
                <Menu>
                    {authUser ? (
                        <>
                            <Username>{authUser.email}</Username>
                            <Button rounded inverse variant="secondary" onClick={() => { firebase.signOut() }}>Odhlásiť</Button>
                        </>
                    )
                        :
                        <Link to="/login"><Button rounded inverse variant="success">Prihlásiť</Button></Link>
                    }
                </Menu>
            </WidthLimiter>
        </Wrapper>
    );
}

export default Header;