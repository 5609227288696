
export const validate = (values: any) => {
    let errors: { [key: string]: string } = {};
    if (!values.totalPayment) {
        errors.totalPayment = "Nevyplnená suma";
    }

    if (!values.paymentDeadline) {
        errors.paymentDeadline = "Nevyplnená doba splatnosti";
    }

    if (!values.firstPaymentDate) {
        errors.firstPaymentDate = "Nevyplnený dátum prvej splátky";
    }

    return errors;
}