import React, { useReducer } from 'react';

interface Props {
    initialValue?: any;
    rootReducer: any;
    children: React.ReactChild;
}

const StoreContext = React.createContext<[any, any] | null>(null);


const StoreProvider = (props: Props) => {
    const initialState = props.rootReducer(props.initialValue, { type: '__INIT__' });
    const [state, dispatch] = useReducer(props.rootReducer, initialState);
    return (
        <StoreContext.Provider value={[state, dispatch]} >
            {props.children}
        </StoreContext.Provider>
    );
}

export { StoreContext };
export default StoreProvider;