import styled from "styled-components/macro";

export const Label = styled.label`
    font-weight: 600;
    font-size: .9rem;

    input, textarea, select {
        margin-top: 0.3rem;
    }
`;

export default Label;
