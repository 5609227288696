export const colors = {
    primary: "#3381ec",
    secondary: "#e0e0e0",
    success: "#3AB95A",
    danger: "#EB3941",
    title: "#d9492f",
    border: "#e2e2e2",
}

export const contractVariables = [
    'suma',
    'dobaSplatnosti',
    'mesacnaSplatka',
    'den',
    'akontacia',
    'prvaSplatka',
    'datum',
    'produktNazov'];