import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    max-width: 350px;
    margin: 0 auto;
    background: white;
    border: 1px solid #fafafa;
    padding: 18px;
    border-radius: 6px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    color: #1f1f1f;
`;

interface Props {
    children: React.ReactNode;
}

const Modal = ({ children, ...rest }: Props) => {
    return (
        <Wrapper {...rest}>
            {children}
        </Wrapper>
    )
}

export default Modal;